var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkoutResume" }, [
    _c("h5", { staticClass: "sidebarStepTitle" }, [_vm._v("Il tuo carrello")]),
    _c("div", { staticClass: "sidebarResumeBlock salabam" }, [
      _c("br"),
      _c("br"),
      _c("div", { staticClass: "sidebarRow" }, [
        _c("span", [
          _vm._v(
            "\n                Salabam " +
              _vm._s(_vm._f("capitalize")(_vm.focus)) +
              " " +
              _vm._s(_vm._f("capitalize")(_vm.band)) +
              " "
          ),
          _c("span", [
            _vm._v(_vm._s(_vm.nights) + " "),
            _vm.nights > 1
              ? _c("span", [_vm._v("notti")])
              : _c("span", [_vm._v("notte")]),
          ]),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.mixinRoundTwoDigits(_vm.price)) + " €")]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "sidebarRow" }, [
        _c("span", [_vm._v("Totale")]),
        _c("span", [_vm._v(_vm._s(_vm.mixinRoundTwoDigits(_vm.price)) + " €")]),
      ]),
      _vm.mixinHasDiscount() > 0
        ? _c("div", { staticClass: "sidebarRow" }, [
            _c("span", { staticClass: "lighter" }, [
              _vm._v(
                "Sconto speciale " +
                  _vm._s(_vm.$config.integration.customerDisplayName) +
                  " -" +
                  _vm._s(100 - 100 * _vm.mixinHasDiscount()) +
                  "%"
              ),
            ]),
            _c("span", { staticClass: "nowrap" }, [
              _vm._v(
                _vm._s(
                  _vm.mixinRoundTwoDigits(
                    _vm.price * (1 - _vm.mixinHasDiscount())
                  )
                ) + " €"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.mixinHasDiscount() > 0
        ? _c("div", { staticClass: "sidebarRow" }, [
            _vm.isJointly && !_vm.hasEnoughAvailability
              ? _c("span", { staticClass: "lighter" }, [
                  _vm._v("Da pagare con carta di credito sul portale Jointly"),
                ])
              : _c("span", { staticClass: "lighter" }, [
                  _vm._v("Totale per te"),
                ]),
            _c("span", { staticClass: "nowrap" }, [
              _vm._v(
                _vm._s(
                  _vm.mixinRoundTwoDigits(_vm.price * _vm.mixinHasDiscount())
                ) + " €"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.mixinHasDiscount() == -1 && !_vm.isJointly && _vm.availability > 0
        ? _c("div", { staticClass: "sidebarRow" }, [
            _c("span", { staticClass: "lighter" }, [
              _vm._v("Credito residuo dopo il pagamento"),
            ]),
            _vm.hasEnoughAvailability
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.mixinRoundTwoDigits(_vm.availability - _vm.price)
                    ) + " €"
                  ),
                ])
              : _c("span", [_vm._v("0 €")]),
          ])
        : _vm._e(),
    ]),
    this.$route.name == "checkout" && _vm.fees !== 0
      ? _c("div", { staticClass: "sidebarResumeBlock fees" }, [
          _c("strong", [_vm._v("Costi obbligatori non inclusi")]),
          _vm.bookingData.totals.mandatory_tax !== 0
            ? _c("div", { staticClass: "sidebarRow" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top",
                        value:
                          "Il costo della tassa di soggiorno viene definito dall'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell'Amministrazione Comunale o a contattare la struttura alberghiera.",
                        expression:
                          "'Il costo della tassa di soggiorno viene definito dall\\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\\'Amministrazione Comunale o a contattare la struttura alberghiera.'",
                        modifiers: { hover: true, top: true },
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "\n              Tassa di soggiorno\n              "
                    ),
                    _c("SvgInfoCircle"),
                  ],
                  1
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.mixinRoundTwoDigits(
                        _vm.bookingData.totals.mandatory_tax
                      )
                    ) + " €"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.bookingData.totals.mandatory_fee !== 0
            ? _c("div", { staticClass: "sidebarRow" }, [
                _c("span", [_vm._v("Spese di pulizia ed utenze")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.mixinRoundTwoDigits(
                        _vm.bookingData.totals.mandatory_fee
                      )
                    ) + " €"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.bookingData.totals.resort_fee !== 0
            ? _c("div", { staticClass: "sidebarRow" }, [
                _c("span", [_vm._v("Resort fee")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.mixinRoundTwoDigits(_vm.bookingData.totals.resort_fee)
                    ) + " €"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._m(1),
        ])
      : this.$route.name == "checkout"
      ? _c("div", { staticClass: "sidebarResumeBlock" }, [
          _c("strong", [_vm._v("Costi obbligatori non inclusi")]),
          _vm._v("\n       \n      "),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _vm._v(
              "\n        La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.\n      "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("small", [_vm._v("Da pagare in struttura al check-in")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="checkoutTicketCompliments">
        <div v-if="!loadingEtc">
            <div :class="canUseEtc ? '' : 'cannotUseEtc'">

                <div class="edenredGui">
                    <!--<p>Utilizza i tuoi Ticket Compliments Edenred</p>-->
                    <!--<div class="logoEdenred"><SvgEdenredLogoTransparent /></div>-->
                    <div class="edenredPanel">
                        <div class="edenredLogo">
                            <img src="/assets/images/etc_transparent_checkout.png" />
                        </div>
                        <div class="edenredText">
                            <span>Accesso a Edenred effettuato</span>
                            <br>
                            <span class="etcLogout" @click="mixinLogout()">esci</span>
                        </div>
                    </div>
                </div>

                <div v-if="!canUseEtc" class="cannotUseEtcAlert">
                    <span class="danger">
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.878 11.3112C15.3892 8.73332 12.9524 4.5122 11.4636 1.93472C9.97497 -0.644906 7.5382 -0.644906 6.04921 1.93472C4.56129 4.5122 2.12435 8.73332 0.635359 11.3112C-0.853271 13.8904 0.364487 16 3.34264 16C6.32044 16 11.1929 16 14.1703 16C17.1481 16 18.3668 13.8904 16.878 11.3112ZM8.68462 13.1522C7.9226 13.1522 7.30577 12.5348 7.30577 11.773C7.30577 11.011 7.9226 10.3934 8.68462 10.3934C9.44681 10.3934 10.064 11.011 10.064 11.773C10.064 12.5346 9.44681 13.1522 8.68462 13.1522ZM10.1151 8.12954C10.1151 8.91926 9.47435 9.56024 8.68462 9.56024C7.89507 9.56024 7.25445 8.91926 7.25445 8.12954V5.67775C7.25445 4.88784 7.89507 4.24723 8.68462 4.24723C9.47435 4.24723 10.1151 4.88784 10.1151 5.67775V8.12954Z" fill="#FF0000"/>
                        </svg>
                        Non sono disponibili Ticket Compliments sul tuo account Edenred
                    </span>
                    <!--<p>
                        Non disponi di Ticket Compliments &reg; oppure sono di importo superiore al costo della prenotazione (non sono possibili resti). Puoi comunque prenotare saldando per intero tramite carta di credito
                    </p>-->
                </div>

                <div class="vouchersTable" v-else>
                    <div class="vouchersBlock">
                        <div class="vouchersTableRow headingRow">
                            <div>buono</div>
                            <div>quantità</div>
                            <div>totale</div>
                        </div>
                    </div>
                    <hr>
                    <div class="vouchersBlock">
                        <div v-for="(voucher,index) in (vouchers)" v-bind:key="index">
                            <div class="vouchersTableRow vouchers">
                                <div class="voucherValue">€ {{voucher.value}}</div>
                                <div class="voucherCount">
                                    <span @click="removeVoucher(index)" class="setVoucherIcon minusOk" v-if="voucher.used > 0">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#3471DC"/>
                                            <path d="M5.85141 8.04H9.15141V9.288H5.85141V8.04Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span class="setVoucherIcon minusKo" v-else>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#C4C4C4"/>
                                            <path d="M5.85141 8.04H9.15141V9.288H5.85141V8.04Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span v-bind="calculating">
                                        {{voucher.used}}
                                    </span>
                                    <span @click="addVoucher(index)" class="setVoucherIcon plusOk" v-if="voucher.used < voucher.count">
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#3471DC"/>
                                            <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="white"/>
                                        </svg>
                                    </span>
                                    <span class="setVoucherIcon plusKo" v-else>
                                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="7.5" cy="7.5" r="7.5" fill="#C4C4C4"/>
                                            <path d="M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z" fill="white"/>
                                        </svg>
                                    </span>                                
                                </div>
                                <div class="voucherTotal">€ {{voucher.amount}}</div>
                            </div>
                            <hr />
                        </div>
                        <div class="vouchersTableRow vouchers">
                            <div class="voucherValue">Totale</div>
                            <div class="voucherCount"></div>
                            <div class="voucherTotal">€ {{etcTotal}}</div>
                        </div>
                    </div>
                </div>

                <div class="etcLower">
                    <div class="etcAutoSelect" v-if="canUseEtc">
                        <p>Utilizza la combinazione di buoni Ticket Compliments consigliata</p>
                        <CustomSwitch v-bind="calculating" :status="etcAutoSelect" :size="'sm'" :switchUniqueKey="'etc-auto-select'" v-on:switch-change="etcAutoSelectCalculation"/>
                    </div>
                </div>
                
            </div>
        </div>
        <div v-else><Spinner /></div>
    </div>
</template>

<script>
    import Api from '../../../api'
    //import SvgEdenredButtonWhite from './../../svg/edenred-button-blue'
    //import SvgEdenredLogoWhite from './../../svg/edenred-logo-white'
    //import SvgEdenredLogoTransparent from './../../svg/etc-logo-transparent'
    import Spinner from './../../atoms/spinner'
    import CustomSwitch from './../../atoms/custom-switch'
    //import ResumePaymentEtc from './resume-payment-etc'

    export default {
        name: 'sidebarCheckoutTicketCompliments',
        props: {
            checkoutAmount: Number,
        },
        components: {
            //SvgEdenredButtonWhite,
            //SvgEdenredLogoWhite,
            //SvgEdenredLogoTransparent,
            Spinner,
            CustomSwitch,
            //ResumePaymentEtc,
        },
        computed: {
            token: {
                get() { return this.$store.state.token },
                set(value) { this.mixinSendMutation('setToken',value) }
            },
        },
        data() {
            return {
                etcAutoSelect: false,
                calculating: false,
                canUseEtc: false,
                etcTotal: 0,
                loading: false,
                loadingEtc: false,
                vouchers: [],
            }
        },
        mounted()
        {
            let _this = this

                _this.loadingEtc = true
                Api.getBalances({
                    token: _this.token,
                    // se viene passato vouchers entra in modalità debug (usa i voucher finti, l'acquisto non andrà mai a buon fine)
                    /*vouchers: [
                        {count:10,value:20},
                        {count:15,value:10},
                        {count:5,value:30}
                        //{count:10,value:200},
                    ]*/
                })
                .then(response => {
                    _this.availability = response.availability
                    _this.vouchers = response.vouchers
                    
                    _this.etcReset()

                    if(_this.vouchers.length > 0)
                    {
                        _this.canUseEtc = (_this.vouchers[0].value <= _this.checkoutAmount)
                    }
                    
                    if(_this.$config.integration.availability !== _this.availability)
                    {
                        _this.mixinSendMutation('setAvailability', _this.availability)
                    }
                    _this.loadingEtc = false
                })
        },
        methods:
        {
            etcReset()
            {
                this.vouchers.forEach((v,index) => {
                    this.vouchers[index].used = 0,
                    this.vouchers[index].amount = 0
                })
                this.vouchers.sort(function(a, b) {
                    return a.value - b.value;
                })
                this.etcAutoSelect = false
                this.etcTotalUpdated()            
            },
            etcAutoSelectCalculation(e)
            {
                if(window.LogRocket) window.LogRocket.track('etc_autoSelectCalculation')

                if(e == false)
                {
                    this.etcAutoSelect = false
                    this.etcReset()
                    return
                }

                this.etcAutoSelect = true
                let residual = this.checkoutAmount

                for(let v=this.vouchers.length-1; v >= 0; v--)
                {
                    let voucher = this.vouchers[v],
                        //amount = voucher.amount,
                        count = voucher.count,
                        value = voucher.value
                        //used = voucher.used

                    this.vouchers[v].used = Math.min(Math.floor(residual / value),count)
                    this.vouchers[v].amount = this.vouchers[v].used * this.vouchers[v].value

                    residual = residual - this.vouchers[v].amount
                }
                this.etcTotalUpdated()
            },
            addVoucher(index)
            {
                if(!this.canUseEtc) return

                this.etcAutoSelect = false
                this.vouchers[index].used = this.vouchers[index].used + 1
                this.vouchers[index].amount = (this.vouchers[index].used)*(this.vouchers[index].value)
                this.etcTotalUpdated()
            },
            removeVoucher(index)
            {
                if(!this.canUseEtc) return

                this.etcAutoSelect = false
                this.vouchers[index].used = this.vouchers[index].used - 1
                this.vouchers[index].amount = (this.vouchers[index].used)*(this.vouchers[index].value)
                this.etcTotalUpdated()
            }, 
            etcTotalUpdated()
            {
                let etcPayments = {
                    amount: 0,
                    vouchers: [],
                }

                this.calculating = true
                let etcTotal = 0
                this.vouchers.forEach(voucher => {
                    etcTotal += voucher.amount
                    if(voucher.used !== 0)
                    {
                        (etcPayments.vouchers).push({
                            count: voucher.used,
                            value: voucher.value
                        })
                    }
                });
                etcPayments.amount = etcTotal
                this.etcTotal = etcTotal
                this.$emit('sidebarBlockChanged', {
                    name: 'checkoutTicketCompliments',
                    fields: etcPayments, //finirà su bookingData
                })

                this.calculating = false
            }                       
        }
    }

</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    
    .checkoutTicketCompliments
    {
        .cannotUseEtc
        {
            .resumePayment *,
            .vouchersTable *
            {
                color:$gray-600 !important;
            }
            .vouchersBlock
            {
                border-bottom:1px solid $gray-600 !important;
            }
            .vouchersTable svg
            {
                circle
                {
                    fill: $gray-600 !important;
                }
                path
                {
                    fill: $gray-500 !important;
                }
            }
        }
        .vouchersTable
        {
            display:flex;
            flex-direction: column;
            text-align:center;
            hr
            {
                display:block;
                flex:1;
                min-width:100%;
            }
            .vouchersBlock
            {
                width:100%;
                min-width:100%;
                //margin-bottom:0.5rem;
            }
            .vouchersTableRow
            {
                //padding:0.5rem 0;
                width:100%;
                display:flex;
                &>div
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
                &.vouchers>div
                {
                    padding:8px 0;
                }
            }
            strong
            {
                font-size: 14px;
            }
            .voucherCount
            {
                .setVoucherIcon
                {
                    cursor:pointer;
                }
                display:flex;
                span
                {
                    width:33%;
                    min-width:33%;
                    flex:33%;
                }
            }
        }

        .etcLower
        {
            text-align:right;
            .etcAutoSelect
            {
                display:flex;
                align-items:center;
                justify-content: center;
                //margin-bottom:0.5rem;
                p
                {
                    margin-bottom:0;
                }
            }
            .custom-switch
            {
                margin-left:1rem;
                display:flex;
                align-items:center;
                justify-content:center;
                label
                {
                    margin-bottom:0;
                }
            }
        }


        $etcBlue: #1D204C;
        $etcRed: #C0272D;
        $etcGray: #747474;
        $etcLightGray: #8C8C8C;
        $etcWhite: #E9EBF0;
        $etcCyan: #3471DC;
        /* questi sono i pesi indicati nelle guidelines, ma se uso questi l'interfaccia viene troppo diversa dal loro stesso documento
        $weightRegular: 400;
        $weightMedium: 500;
        $weightSemibold: 600;
        $weightBold: 700;
        */
        $weightRegular: 400;
        $weightMedium: 500;
        $weightSemibold: 600;
        $weightBold: 700;
        
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

        .cannotUseEtcAlert
        {
            text-align:left;
            margin:1.25rem 0;
            .danger
            {
                color:$etcRed;
                font-size: 14px;
                font-weight: $weightMedium;
                display:flex;
                line-height: 120%;
                svg
                {
                    min-width:20px;
                    transform:translateY(2px);
                    margin-right:4px;
                }
            }
        }

        .headingRow
        {
            font-family: 'Montserrat', sans-serif;
            color:$etcLightGray;
            font-weight: $weightSemibold;
            font-size:14px;
            text-transform:uppercase;
        }
        .voucherValue,
        .voucherCount,
        .voucherTotal,
        .resumePayment .value,
        .totalLabel
        {
            font-family: 'Montserrat', sans-serif;
            color:$etcBlue;
            font-weight: $weightMedium;
            font-size: 20px;
        }
        .setVoucherIcon
        {
            svg
            {
                width:20px;
                height:20px;
            }
        }

        .etcAutoSelect
        {
            margin-top:1rem;
            p
            {
                order:2;
                line-height: 120%;
                color:$etcBlue;
                font-weight: $weightSemibold;
                font-size: 14px;
                padding-left:8px;
            }
            .custom-switch
            {
                order:1;
                input:checked ~ label
                {
                    background:$etcCyan;
                }
            }
        }

        .edenredGui
        {
            *
            {
                font-family: 'Montserrat', sans-serif;
                font-weight: $weightRegular;
            }

            text-align:center;
            margin:0 0 24px 0;
            .edenredPanel
            {
                background:$white;
                box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.1);
                padding:16px;
                font-size: 14px;
                font-weight:600;
                color:$etcBlue;
                display:flex;
                align-items:center;
                text-align: left;
                line-height:120%;
                font-weight: $weightSemibold;
            }
            .edenredText
            {
                padding-left:16px;
                *
                {
                    font-weight:600;
                    color:$etcBlue;
                }
            }
            .etcLogout
            {
                color:$etcRed;
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: $weightBold;
                margin-top:6px;
                display:inline-block;
                font-weight: 12px;
            }
            .edenredLogo
            {
                max-width: 140px;
            }

            /*
            .logoEdenred
            {
                margin:1rem auto;
                border:2px solid $etcBlue;
                border-radius:48px;
                width:100%;
                min-width:140px;
                min-height:60px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding:8px 0;
            }
            */
            p
            {
                font-weight:500;
                font-family: 'Montserrat', sans-serif;
                color:$etcGray;
                font-size: 14px;
                margin-bottom: 12px;
            }
        }   
    }
</style>
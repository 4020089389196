<template>
<!--
    LOGICA CHECKOUT PER UTENTI SSO:
    - welfare true "non jointly": non può acquistare oltre il credito welfare
    - welfare true "jointly": può acquistare oltre al credito, in sidebar non viene proposto stripe ma tutto l'importo va come "transazione welfare" (quindi payments welfare)
    - welfare false può acquistare oltre il credito integrando (in fase di checkout salabam) quello che manca con stripe
-->
    <div id="sidebar" class="sidebarSso">

        <div class="checkoutBack" @click="mixinGoBack()">
            <BtnBack :text="'Torna indietro'" />
        </div>
            
        <!-- credito welfare non sufficiente (mostro solo se non può usare la carta di credito) -->
        <div v-if="
            (!useCreditCard && !enoughAvailability) &&
            (mixinGetIntegrationInfo().integration.integration !== 'jointly')
        ">
            <b-alert variant="danger" class="text-center" show><b><span>Credito welfare</span> non sufficiente!</b></b-alert>
        </div>

        <div class="sidebar-inner">
            <div id="sidebarCheckout" class="hasBackground">

                <div class="sidebarStep bgDark">
                    <CheckoutUserInfo /><!-- Credito welfare/info utente -->
                    <hr />
                    <CheckoutResume :bookingData="bookingData" :price="bookingData.public_price" :fees="fees" /><!-- Riepilogo dei costi -->
                </div>
                
                <!-- credito welfare sufficiente oppure può usare carta di credito -->
                <div class="sidebarStep bgGray" v-if=" (enoughAvailability || useCreditCard) || (mixinGetIntegrationInfo().integration.integration == 'jointly')">
                    
                    <b-form-group id="checkout-form" :valid-feedback="validFeedback()">

                        <!-- Dati del personali -->
                        <h5 class="sidebarAccordionTitle">
                            <!--<div v-b-toggle.checkoutStatusUserData>-->
                            <div>
                                <span class="checkoutDynamicBlockTitle">
                                    <span class="stepNumber">1</span> Dati personali <!--<div class="sidebarAccordionIcon"><SvgChevronDown class="chevron" /></div>-->
                                </span>
                            </div>
                        </h5>

                        <div class="sidebarResumeBlock">
                            <!-- da rifattorizzare  in un componente CheckoutCustomBillToPax -->
                            <!--<p class="text-center"><small>I campi con l'asterisco * sono obbligatori</small></p>-->

                            <div v-show="allowCustomBeneficiary">
                                <div class="sidebarRow">
                                    <div class="customInputBlock">
                                        <b-form-input class="form-control-lg" ref="customBeneficiaryFirstname" :required="true" v-model="customBeneficiaryFirstname" type="text" placeholder="Mario" />
                                        <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Il tuo nome'"/>
                                    </div>
                                </div>
                                <div class="sidebarRow">
                                    <div class="customInputBlock">
                                        <b-form-input class="form-control-lg" ref="customBeneficiaryLastname" :required="true" v-model="customBeneficiaryLastname" type="text" placeholder="Rossi" />
                                        <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Il tuo cognome'"/>
                                    </div>
                                </div>

                                <div class="sidebarRow">
                                    <h5 class="sidebarStepTitle">Scegli il grado di parentela</h5>
                                </div>

                                <div class="sidebarRow">
                                    <div class="customInputBlock">                                        
                                        <b-form-select class="form-control-lg" ref="checkoutGuestRelation" :required="true" v-model="guestRelation" :options="beneficiaryRelations"></b-form-select>        
                                        <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'L\'intestatario del voucher deve essere uno dei possibili beneficiari del tuo piano welfare, quali i familiari'"/>
                                    </div>
                                </div>
                                <div class="sidebarRow">
                                    <div class="customInputBlock">
                                        <b-form-input class="form-control-lg" ref="customBeneficiaryFiscalCode" :required="true" v-model="customBeneficiaryFiscalCode" type="text" placeholder="codice fiscale" />
                                        <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Codice fiscale'"/>
                                    </div>
                                </div>
                            </div>
                            
                            <div v-show="!allowCustomBeneficiary">
                                <div class="sidebarRow">
                                    <h5 class="sidebarStepTitle">Scegli il beneficiario</h5>
                                </div>
                                <div class="sidebarRow">
                                    <div class="customInputBlock">
                                        <b-form-select class="form-control-lg" ref="checkoutBeneficiaries" :required="true" v-model="beneficiaryId" :options="beneficiaries"></b-form-select>        
                                        <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Quella che vedi quì è la lista dei possibili beneficiari del tuo credito welfare così come inserita nel tuo portale welfare. Se il nome del viaggiatore non risulta tra i beneficiari devi tornare sul portale welfare, inserirlo tra i beneficiari e poi fare di nuovo dall\'inizio il processo di prenotazione su VadoBay'"/>
                                    </div>
                                </div>
                            </div>

                            <div class="sidebarRow">
                                <div class="customInputBlock">
                                    <b-form-input class="form-control-lg" ref="checkoutEmail" :required="true" v-model="guestEmail" type="email" placeholder="Email" />
                                    <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Il voucher Salabam con il codice e le istruzioni su come prenotare sarà inviato a questo indirizzo'"/>
                                </div>
                            </div>

                            <div class="sidebarRow">
                                <div class="customInputBlock">
                                    <b-form-input class="form-control-lg" ref="checkoutEmailConfirm" :required="true" v-model="guestEmailConf" type="email" placeholder="Conferma email" />
                                    <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Te la facciamo riscrivere per scoprire eventuali errori di digitazione'"/>
                                </div>
                            </div>

                            <div class="sidebarRow">
                                <div class="customInputBlock">
                                    <b-form-input class="form-control-lg" ref="checkoutPhone" :required="true" v-model="guestPhone" type="tel" placeholder="Telefono" autocomplete="tel" />
                                    <SvgInfoCircle class="svgInfo" v-b-popover.hover.top="'Avere il tuo numero ci permetterà di contattarti velocemente in caso di qualsiasi problema'"/>
                                </div>
                            </div>
                            <hr class="stepSeparator" />
                            <!-- da rifattorizzare  in un componente CheckoutCustomBillToPax -->

                            <!-- SIDEBAR REFACTOR: sub component della sidebar, in futuro saranno tutti così; potenzialmente anche il tasto Conferma di ciascun cassetto potrebbe essere gestito a livello di sub component -->
                            <CheckoutBillToPax
                                :inputSize="sidebarBlocks.checkoutBillToPax.inputSize"
                                :ref="sidebarBlocks.checkoutBillToPax.ref"
                                v-if="sidebarBlocks.checkoutBillToPax.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"                            
                            />
                            <hr class="stepSeparator" v-if="sidebarBlocks.checkoutBillToPax.initialize" />

                            <CheckoutTicketCompliments
                                :checkoutAmount="checkoutAmount"
                                :ref="sidebarBlocks.checkoutTicketCompliments.ref"
                                v-if="sidebarBlocks.checkoutTicketCompliments.initialize"
                                v-on:sidebarBlockChanged="sidebarBlockChange"
                            />
                            <hr class="stepSeparator" v-if="sidebarBlocks.checkoutTicketCompliments.initialize" />

                            <!-- inizio metodi di pagamento -->
                            <div><!-- diventerà b-collapse nel refactor, vedi sidebar-checkout-public -->
                                <!-- utenti non jointly e utenti sso con welfare==false che devono integrare -->
                                <div v-if="useCreditCard && mixinRoundTwoDigits(creditCardAmount) > 0">
                                    <Stripe ref="stripe" :amount="creditCardAmount" v-on:stripe-change="stripeChange" v-on:stripe-confirm="stripeConfirm" />
                                    <hr class="stepSeparator" />
                                </div>
                            </div>
                            <!-- fine metodi di pagamento -->

                            <div class="privacy-box">
                                <b-form-checkbox v-model="guestPrivacy" ref="checkoutPrivacy" required type="checkbox" name="checkbox" id="checkbox-privacy" />
                                <label for="checkbox-privacy">
                                    <router-link :to="{name: 'terms'}" target="_blank" class="nounderline">
                                        <span class="secondary">Ho preso visione delle condizioni d'uso e le accetto</span>
                                    </router-link>
                                </label>
                            </div>

                            <div class="privacy-box">
                                <b-form-checkbox v-model="guestGdpr" ref="checkoutGdpr" required type="checkbox" name="checkbox" id="checkbox-gdpr" />
                                <label for="checkbox-gdpr">
                                    <p class="mb-0">
                                        <span class="secondary"><a :href="site.urlPrivacy" target="_blank">Ho preso visione della privacy policy e la accetto</a></span>
                                    </p>
                                </label>
                            </div>

                            <div class="privacy-box" v-if="disclaimerCheckout !== undefined">
                                <label for="checkbox-gdpr">
                                    <p class="checkboxInfo" v-html="disclaimerCheckout"></p>
                                </label>
                            </div>

                            <br>

                            <Spinner v-if="loading" v-bind:msg="loadingText" />
                            <div v-else class="d-block text-center"><!-- evento solo per acquisto, è collegato al tracking fb pixel initiate checkout-->
                                
                                <b-button v-if="mixinGetIntegrationInfo().mode == 'booking'" :disabled="validFeedback() !== 'ok'" @click="checkoutConfirm">PRENOTA!</b-button>
                                
                                <div v-if="mixinGetIntegrationInfo().mode == 'boxset'">
                                    <div v-if="(enoughAvailability || mixinGetIntegrationInfo().integration.integration == 'jointly')">
                                        <Otp
                                            v-if="otp_required && (otp_check == false)"
                                            :button_variant="'secondary'"
                                            @otp-check="(value) => { otp_check = value }"
                                            @otp-event="(value) => { this.trackOtpEvent(value)}"
                                            @otp-purchase="checkoutConfirm()"
                                            :disabled="validFeedback() !== 'ok'"
                                            cta="ACQUISTA!"
                                        />
                                        <!-- credito welfare sufficiente e/o sso jointly: non faccio controllo su totale carta di credito -->
                                        <b-button v-if="otp_required == false || (otp_required && otp_check)" :disabled="validFeedback() !== 'ok'" @click="checkoutConfirm">PRENOTA!</b-button>
                                    </div>
                                    
                                    <div v-else-if="mixinGetIntegrationInfo().welfare == false">
                                        <Otp
                                            v-if="otp_required && (otp_check == false)"
                                            :button_variant="'secondary'"
                                            @otp-check="(value) => { otp_check = value }"
                                            @otp-event="(value) => { this.trackOtpEvent(value)}"
                                            @otp-purchase="getIntentClientSecret()"
                                            :disabled="validFeedback() !== 'ok' || !stripeInfoCheck"
                                            cta="ACQUISTA!"
                                        />
                                        <!-- credito non sufficiente, non caso jointly, sso con welfar==false: devo usare carta di credito che quindi va verificata -->
                                        <b-button v-if="otp_required == false || (otp_required && otp_check)" :disabled="validFeedback() !== 'ok' || !stripeInfoCheck" @click="getIntentClientSecret">ACQUISTA!</b-button>
                                    </div>
                                    
                                    <!-- credito non sufficiente, non caso jointly, sso con welfar==true: in teoria questo else non lo dovrei mai vedere -->
                                    <span v-else>ACQUISTA!</span>
                                </div>                            
                            </div>
                            
                        </div>        
                    </b-form-group>
                    

                    <!-- confirm only -->
                    <div v-if="this.$route.name == 'confirm'">
                        <a href="/" class="btn btn-block btn-outline-secondary">Torna alla Home</a>
                        <!--
                        TODO rendere contestuale
                        <br>
                        <a href="#" class="btn btn-block btn-outline-secondary">Torna a Easy Welfare</a>-->
                    </div>
                </div>

            </div><!--#sidebarCheckout-->
        </div>


    </div>
</template>

<script>
import Vue from 'vue'
import Api from '../../../api'
import Stripe from '../stripe'
import CheckoutBillToPax from './checkout-bill-to-pax' /* SIDEBAR REFACTOR */
import CheckoutTicketCompliments from './checkout-ticket-compliments' /* SIDEBAR REFACTOR */
import CheckoutUserInfo from './checkout-user-info'
import CheckoutResume from './checkout-resume'
import SvgInfoCircle from './../../svg/info-circle'
import Spinner from './../../atoms/spinner'
import BtnBack from './../../atoms/btn-back'
import Otp from '../otp'

export default {
    name: 'sidebar-sso',
    components:
    {
        Stripe,
        Spinner,
        SvgInfoCircle,
        CheckoutBillToPax, /* SIDEBAR REFACTOR */
        CheckoutTicketCompliments, /* SIDEBAR REFACTOR */
        CheckoutUserInfo,
        CheckoutResume,
        BtnBack,
        Otp
    },
    props:
    {
        //bookingData: Object
    },
    computed: {
        availability: {
            get() { return this.$store.state.integration.availability },
            set(value) { this.mixinSendMutation('setAvailabilty',value) }
        },
        availabilityToUse: {
          get() { return (this.availability <= this.checkoutAmount)? this.availability : this.checkoutAmount },
          set() { }
        },
        token: {
            get() { return this.$store.state.token },
            set(value) { this.mixinSendMutation('setToken',value) }
        },
        bookingData: {
            get() { return this.$store.state.bookingData },
            set(value) { this.mixinSendMutation('setBookingData',value) }
        },
        affiliateReferenceId: {
            get() { return this.$store.state.affiliateReferenceId },
            set(value) { this.mixinSendMutation('setAffiliateReferenceId',value) }
        },
    },
    data() {        
        return {
            //enoughAvailability: (this.$route.name == 'checkout' && this.availability > 0 && this.bookingData.public_price > this.availability),
            useCreditCard: false,
            enoughAvailability: false,
            creditCardAmount: 0,
            checkoutAmount:0,
            stripeInfoCheck: false,

            status: 'not_accepted',
            //availability: Vue.prototype.$config.integration.availability,
            displayName: Vue.prototype.$config.integration.displayName,
            avatarSrc: Vue.prototype.$config.integration.avatarSrc,
            loading: false,
            guestFirstName: '',
            guestLastName: '',
            guestEmail: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestEmailConf: (process.env.VUE_APP_MODE == 'development')? 'dev@salabam.com' : '',
            guestPhone: (process.env.VUE_APP_MODE == 'development')? '3934288350' : '',
            guestPrivacy: (process.env.VUE_APP_MODE == 'development')? true : false,
            guestGdpr: (process.env.VUE_APP_MODE == 'development')? true : false,
            disclaimerCheckout: Vue.prototype.$config.guiSettings.disclaimerCheckout,
            beneficiaryId: '', // default vuoto, non cambiare, viene usato al checkout, setBeneficiaryById
            beneficiaries: [],
            fees: 0,
            allowCustomBeneficiary: false,
            customBeneficiaryFirstname: '',
            customBeneficiaryLastname: '',
            customBeneficiaryFiscalCode: '',
            beneficiaryRelations: [],
            guestRelation: 0,
            otp_check: false,
            otp_required: (Vue.prototype.$config.integration && Vue.prototype.$config.integration.otpRequired)? Vue.prototype.$config.integration.otpRequired : false,


            /* SIDEBAR REFACTOR: oggetto che conterrà tutti i sottoblocchi della sidebar richiesti per il checkout (dati beneficiario, dc, etc, billToPax, ecc) */
            sidebarBlocks: {
                checkoutBillToPax: {
                    initialize: this.mixinCanBillToPax(),   // per inizializzarlo o meno nel template; è il valore che viene controllato anche per prelevare i campi prima della conferma
                    inputSize: 'lg',
                    ref: 'checkoutBillToPax',
                    status: false,                          // tiene traccia della validazione riuscita o meno dei campi
                    fields: {}                              // campi che vengono eventualmente inviati a bookingData: se il blocco invia un evento sidebarBlockChanged questi campi vengono aggiornati con quelli contenuti nell'evento
                },
                checkoutTicketCompliments: {
                    //initialize: this.mixinUseTicketCompliments(),
                    initialize: this.mixinGetIntegrationInfo().integration.integration == 'edenred_etc',
                    //inputSize: 'lg',
                    ref: 'checkoutTicketCompliments',
                    status: true,                           // sempre true perché il blocco ticketCompliments non ha una validazione (il controllo sui totali viene effettuato internamente al componente)
                    fields:                                 // finirà su bookingData.payments.etc
                    {
                        amount:0,
                        vouchers:[]
                    }
                }
            },

            loadingText: (this.mixinGetIntegrationInfo().mode == 'booking' ? 'Prenotazione in corso... non abbandonare o ricaricare la pagina!' : 'Acquisto in corso... non abbandonare o ricaricare la pagina!'),
          site: Vue.prototype.$config.site,
        }
    },
    beforeMount()
    {
        //window.console.log('before mount')
    },
    mounted()
    {
        let _this = this

        /* i calcoli dei totali sono stati rifattorizzati in setCheckoutTotals() perché mentre prima venivano fatti solo al mounted() ora vanno agigornati se/quando cambiano i ticket compliments */
        this.setCheckoutTotals()

        let parameters = {
            token: _this.token,
            routeName: this.$route.name,
        }
        if(_this.token && _this.$route.name == 'checkout')
        {
            _this.loading = true
            Api
            .getBeneficiary(parameters)
            .then((data) => {
                _this.allowCustomBeneficiary = data.allowCustomBeneficiary
                let bens = Object.values(data.beneficiaries)
                bens.forEach(ben => {
                    _this.beneficiaries.push({
                        value: ben.id,
                        name: ben.name,
                        surname: ben.surname,
                        fiscalCode: ben.fiscalCode,
                        text: ben.name + ' ' + ben.surname
                    })
                })
                _this.beneficiaryId = (_this.beneficiaries.length !== 0 ? _this.beneficiaries[0].value : 0)
                if(_this.allowCustomBeneficiary)
                {
                    data.beneficiaryRelations.forEach((rel,key) => {
                        _this.beneficiaryRelations.push({
                            value: key,
                            name: rel,
                            text: rel,
                        })
                    })

                    let customBen = bens[Object.keys(bens)[0]]
                    _this.customBeneficiaryFirstname = customBen.name
                    _this.customBeneficiaryLastname = customBen.surname
                    _this.beneficiaryId = customBen.id
                    _this.customBeneficiaryFiscalCode = customBen.fiscalCode
                    _this.guestEmail = customBen.email
                    _this.guestEmailConf = customBen.email
                }
                _this.loading = false
            })
        }
        this.$emit('fb-px-initiate-checkout', {})
    },
    methods:
    {
        setCheckoutTotals() //calcolo dei totali del checkout: prima era fatto una volta sola nel mounted(), ora deve essere un metodo perché possono essere ricalcolati se cambiano i ticketcompliments
        {
            this.checkoutAmount = this.bookingData.public_price
            this.creditCardAmount = this.checkoutAmount 
            
            // IF necessario: altrimenti, se availabilityToUse è pari a -1, viene calcolato creditCardAmount = creditCardAmount - (-1) e si ha 1 euro di troppo
            if(this.availabilityToUse > 0) 
            {
                this.creditCardAmount -= this.availabilityToUse
            }
                        
            // se availability < 0 (es -1) faccio finta sia sufficiente (-1 generalmente indica un problema con il recupero della disponibilità) se ne occuperà l'api al checkout a verificare con il portale welfare se può oppure no acquistare
            this.enoughAvailability = this.availability < 0 || this.bookingData.public_price <= this.availability
            
            // se sto usando ticket compliments devo determinare enoughAvailability in base a checkoutTicketCompliments.fields.amount (che mi da il totale di etc che sto usando) e NON in base ad availability (che mi da il totale degli etc disponibili anche se magari ne sto usando uno soltanto)
            // valutare se conviene astrarre in if(this.sidebarBlocks.checkoutTicketCompliments.initialize)
            if(this.mixinGetIntegrationInfo().integration.integration == 'edenred_etc')
            {
                this.creditCardAmount -= this.sidebarBlocks.checkoutTicketCompliments.fields.amount
                this.enoughAvailability = this.sidebarBlocks.checkoutTicketCompliments.fields.amount >= this.bookingData.public_price
            }

            // non devo essere jointly
            // non devo essere sso con welfare true
            this.useCreditCard = (!(this.mixinGetIntegrationInfo().integration.integration == 'jointly') && !(this.mixinGetIntegrationInfo().welfare == true))        

            if(this.$store.state.bookingData.totals !== undefined)
            {
                this.fees = parseFloat(this.$store.state.bookingData.totals.mandatory_tax + this.$store.state.bookingData.totals.mandatory_fee + this.$store.state.bookingData.totals.resort_fee)
            }
        },
        /* 
        * SIDEBAR REFACTOR: metodo che dovrà gestire la validazione del cassetto aperto della sidebar, momentaneamente targettizzato solo su 'billToPax', in futuro astratto
        * -> aggiunta gestione ticketCompliments
        */
        sidebarBlockChange(e){
            let _this = this

            // blocco validazione campi: è astratto, rimbalza tutto a validFeedback() che effettua il controllo sugli input utente
            _this.sidebarBlocks[e.name].fields = e.fields
            _this.sidebarBlocks[e.name].status = e.status
            this.validFeedback()

            // blocco ticket compliments (se cambia il totale dei TC usati)
            this.setCheckoutTotals()
            //window.console.log(this.sidebarBlocks.checkoutTicketCompliments.fields)
        },        
        validateField(ref, invalid)
        {
            if(invalid)
            {
                ref.$el.classList.add('invalid')
                return false
            }
            ref.$el.classList.remove('invalid')
            return true
        },
        validFeedback()
        {
            // tolti questi 2 check in quanto il nome arriva dai beneficiari
            //if (this.guestFirstName.length < 4) return 'ko'
            //if (this.guestLastName.length < 4) return 'ko'

            if(Object.keys(this.$refs).length == 0) return 'ko'

            let check = true

            check = check && this.validateField(this.$refs.checkoutEmail, this.guestEmail.length < 4)
            check = check && this.validateField(this.$refs.checkoutEmailConfirm, this.guestEmail !== this.guestEmailConf)
            check = check && this.validateField(this.$refs.checkoutPhone, this.guestPhone.length < 4)
            check = check && this.validateField(this.$refs.checkoutPrivacy, !this.guestPrivacy)
            check = check && this.validateField(this.$refs.checkoutGdpr, !this.guestGdpr)

            // beneficiaryId può anche essere 0
            check = check && this.validateField(this.$refs.checkoutBeneficiaries, !(this.beneficiaryId || this.beneficiaryId === 0)? true : false)
            check = check && this.validateField(this.$refs.checkoutEmail, !this.validEmail(this.guestEmail))

            if(this.allowCustomBeneficiary)
            {
                check = check && this.validateField(this.$refs.customBeneficiaryFirstname, this.customBeneficiaryFirstname.length < 2)
                check = check && this.validateField(this.$refs.customBeneficiaryLastname, this.customBeneficiaryLastname.length < 2)
                check = check && this.validateField(this.$refs.customBeneficiaryFiscalCode, this.customBeneficiaryFiscalCode.length !== 16)
                check = check && this.validateField(this.$refs.checkoutGuestRelation, this.guestRelation < 0)
            }

            /* SIDEBAR REFACTOR */
            if(this.sidebarBlocks.checkoutBillToPax.initialize)
            {
                check = check && this.sidebarBlocks.checkoutBillToPax.status
            }

            return (check ? 'ok' : 'ko')

        },
        getIntentClientSecret()
        {
            if(!this.stripeInfoCheck)
            {
                return
            }
            
            this.loading = true

            let stripeName = this.$refs.stripe.$refs.ccname.value
            if(stripeName == '')
            {
                this.loading = false
                return
            }
            this.$refs.stripe.getIntentClientSecret()
        },
        stripeChange(e)
        {
            this.stripeInfoCheck = e
        },
        stripeConfirm(e)
        {
            if(e.error)
            {
                this.loading = false
                let data = {
                    msg: e.message,
                    status: e.status,
                    callback: function() {}
                }
                data.msg = e.result.message
                window.eventBus.$emit('gotNotification',data)
                return
            }

            this.bookingData.payments.cc.paymentIntentId = e.paymentIntentId
            this.bookingData.payments.cc.nameSurname = e.name
            this.checkoutConfirm()
        },
        canFinallyCheckout()
        {
            if (!this.validFeedback() == 'ok') return false           // ridondante, per sicurezza
            if (!this.getPaymentMethodsStatus()) return false
            if (!this.guestPrivacy) return false
            return true
        },
        getPaymentMethodsStatus()
        {
            if (!this.validFeedback() == 'ok') return false           // ridondante, per sicurezza
            if(this.creditCardAmount > 0)
            {
                if (!this.stripeInfoCheck) return false
            }
            return true
        },
        checkoutConfirm()
        {
            if(this.enoughAvailability || this.mixinGetIntegrationInfo().integration.integration == 'jointly')
            {
                this.bookingData.payments.welfare.amount = this.checkoutAmount
            }
            else
            {
                this.bookingData.payments.welfare.amount = Math.max(this.availabilityToUse,0) // può arrivare un -1 che sballa i conti
                this.bookingData.payments.cc.amount = this.creditCardAmount                
            }

            let _this = this,
                parameters = {},
                bookingData = Object.assign({},_this.bookingData)
            
            _this.loading = true

            // PAGAMENTO TUTTO WELFARE
            //bookingData.payments.welfare.amount = bookingData.public_price
            parameters.bookingData = bookingData
            parameters.token = _this.token
            //parameters.guestFirstName = _this.guestFirstName
            //parameters.guestLastName = _this.guestLastName
            parameters.guestFirstName = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].name
            parameters.guestLastName = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].surname
            parameters.guestFiscalCode = _this.beneficiaries.filter(function (b) {
                return b.value == _this.beneficiaryId
            })[0].fiscalCode
            parameters.guestEmail = _this.guestEmail
            parameters.guestEmailConf = _this.guestEmailConf
            parameters.guestPhone = _this.guestPhone
            parameters.guestPrivacy = _this.guestPrivacy

            if(!_this.allowCustomBeneficiary)
            {
                parameters.beneficiaryId = _this.beneficiaryId
            }

            if(_this.allowCustomBeneficiary)
            {
                //parameters.customBeneficiaryFiscalCode = _this.customBeneficiaryFiscalCode                
                parameters.guestFiscalCode = _this.customBeneficiaryFiscalCode
                parameters.guestFirstName = _this.customBeneficiaryFirstname
                parameters.guestLastName = _this.customBeneficiaryLastname
                parameters.guestRelation = _this.beneficiaryRelations[_this.guestRelation].text
            }

            /* SIDEBAR REFACTOR: serie di if che popoleranno bookingData con i set di campi che possono o meno comparire a seconda di come è stata costruita la sidebar */
            if(_this.sidebarBlocks.checkoutBillToPax.initialize) //non controllo lo status perché se è false non dovrei essere arrivato alla conferma
            {
                parameters.guestAddress = _this.sidebarBlocks.checkoutBillToPax.fields.guestAddress
                parameters.guestCity = _this.sidebarBlocks.checkoutBillToPax.fields.guestCity
                parameters.guestPostalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestPostalCode
                parameters.guestProvince = _this.sidebarBlocks.checkoutBillToPax.fields.guestProvince
                parameters.guestFiscalCode = _this.sidebarBlocks.checkoutBillToPax.fields.guestFiscalCode
            }

            if(_this.sidebarBlocks.checkoutTicketCompliments.fields.amount > 0)
            {

                parameters.bookingData.payments.etc = _this.sidebarBlocks.checkoutTicketCompliments.fields

                // TODO fix temporaneo
                // usando ticket compliments resta valorizzato payments.welfare.amount con il valore del public_price
                // (verificare se accade anche usando etc+cc)
                if(parameters.bookingData.payments.welfare.amount == parameters.bookingData.payments.etc.amount){
                  parameters.bookingData.payments.welfare.amount = 0
                }

            }

            // TODO verificare
            parameters.boxset_id = parameters.bookingData.boxset_id
            parameters.focus = parameters.bookingData.focus
            parameters.band = parameters.bookingData.band

            window.console.log('CONFERMA')
            window.console.log(parameters.bookingData)

            Api
                .checkoutBoxset(parameters)
                .then((response) => {

                    if(response.status == 300)
                    {
                        _this.mixinSendMutation('setAffiliateReferenceId',response.data.retryWithAffiliateReferenceId)
                        _this.bookingData.affiliateReferenceId = response.data.retryWithAffiliateReferenceId
                        return _this.checkoutConfirm()
                    }

                    _this.loading = false

                    if(response.status == 200)
                    {
                        if(this.$gtm) this.$gtm.trackEvent({
                          event: 'customEvent',
                          category: 'userBehaviour',
                          action: 'confirmedCheckout',
                          //label: Vue.prototype.$config.integration.email,
                          value: parameters.bookingData.public_price,
                        })

                        if(this.$gtm) this.$gtm.trackEvent({
                          event: 'customEvent',
                          category: 'partnerBehaviour',
                          action: 'confirmedCheckout',
                          label: Vue.prototype.$config.integration.company,
                          value: parameters.bookingData.public_price,
                        })

                        if(window.LogRocket) window.LogRocket.track('confirmedCheckout')

                        _this.$emit('sidebar-order-confirmed', {})

                        if(response.data.updateAvailabilityTo) _this.mixinSendMutation('setAvailability', response.data.updateAvailabilityTo)

                        _this.$router.push({
                            name: 'confirm-boxset',
                            params: {
                                integratedOrderId: response.data.integratedOrderId,
                                parsedEmailId: response.data.parsedEmailId,
                                redirectIfConfirmedRedirectTo: false
                            }
                        })
                    }
                })
        },
        validEmail(email){
            return /\S+@\S+\.\S+/.test(email)
        },
        trackOtpEvent(value){

          if(this.$gtm){
            this.$gtm.trackEvent({
              event: 'customEvent',
              category: 'ce',
              action: 'otp',
              label: value
            });
          }

          if(window.clarity) window.clarity('set', 'otp', value)
          if(window.LogRocket) window.LogRocket.track('otp_' + value)

        },
    }  
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .sidebarSso
    {
        .fees
        {
            display:none;
        }
        .user, .info
        {
            margin:0;
            padding:0;
            border:0;
        }
    }
</style>
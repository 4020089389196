var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.required
    ? _c(
        "div",
        { staticClass: "otp" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: "modal-otp",
                  expression: "'modal-otp'",
                },
              ],
              staticClass: "btn",
              attrs: {
                size: _vm.button_size,
                variant: _vm.button_variant,
                disabled: _vm.disabled,
              },
              on: {
                click: function () {
                  if (this$1.status == "REQUIRED") {
                    _vm.$emit("otp-event", "REQUIRED_CTA_CLICK")
                  }
                },
              },
            },
            [_vm._v("\n    Conferma\n  ")]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-otp",
                title: _vm.display_name + " vorremmo essere sicuri che sei tu",
                size: "md",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "otp--modal-requestcode d-flex w-100",
                            class:
                              _vm.status == "OTP_SENT" ||
                              _vm.status == "RATE_LIMITED" ||
                              _vm.status == "NOT_VALID"
                                ? "justify-content-end"
                                : "justify-content-center",
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  size: _vm.button_size,
                                  variant: _vm.button_variant,
                                  disabled: _vm.countdown > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.request_otp()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.status == "OTP_SENT" ||
                                        _vm.status == "RATE_LIMITED" ||
                                        _vm.status == "NOT_VALID"
                                        ? "Richiedi un'altra password"
                                        : "Richiedi password"
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3157837859
              ),
            },
            [
              _c("span", { staticClass: "otp--modal--description" }, [
                _vm._v(
                  "\n      Per garantire la sicurezza del tuo credito welfare invieremo una password temporanea \n      "
                ),
                _vm.by == "MAIL"
                  ? _c("span", [
                      _vm._v("al tuo indirizzo email "),
                      _c("strong", [_vm._v(_vm._s(_vm.sent_to))]),
                    ])
                  : _c("span", [
                      _vm._v("al tuo numero di telefono "),
                      _c("strong", [_vm._v(_vm._s(_vm.sent_to))]),
                    ]),
                _c("br"),
                _c("br"),
                _vm._v(
                  "\n      Richiedila e potrai inserirla in questa finestra per completare la prenotazione.\n    "
                ),
              ]),
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center mt-3" },
                    [
                      _c("b-spinner", {
                        attrs: { variant: _vm.button_variant },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "otp--modal-wrapper" }, [
                    _vm.status == "OTP_SENT" ||
                    _vm.status == "RATE_LIMITED" ||
                    _vm.status == "NOT_VALID"
                      ? _c(
                          "div",
                          {
                            staticClass: "otp--modal-add-code text-center mt-3",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "otp--modal-notify-mail" },
                              [
                                _vm._v(
                                  "Ti abbiamo appena inviato la password temporanea."
                                ),
                                _c("br"),
                                _vm._v(" Controlla la tua email."),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "otp--modal-code-wrapper" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "otp--modal-prefix" },
                                  [_vm._v(_vm._s(_vm.prefix) + "- ")]
                                ),
                                _c("b-form-input", {
                                  ref: "otpCode",
                                  staticClass: "w-75 otp--modal-input",
                                  attrs: {
                                    placeholder: "Inserisci la password",
                                    type: "text",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.validateCodeInput()
                                    },
                                  },
                                  model: {
                                    value: _vm.code,
                                    callback: function ($$v) {
                                      _vm.code =
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                    },
                                    expression: "code",
                                  },
                                }),
                                _c("span", { staticClass: "formError" }),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  size: _vm.button_size,
                                  variant: _vm.button_variant,
                                  disabled: _vm.code.length < 6 || _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.validate_otp()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.cta) + "\n        ")]
                            ),
                            _vm.msg != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "otp--modal-text mb-4 mt-2 font-weight-bold",
                                  },
                                  [_vm._v(_vm._s(_vm.msg))]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "otp--info text-left mt-4" },
                              [
                                _vm.countdown > 0
                                  ? _c("div", { staticClass: "font-italic" }, [
                                      _vm._v(
                                        "Potrai richiedere una nuova password tra " +
                                          _vm._s(_vm.countdown) +
                                          " secondi"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.by == "MAIL"
                                  ? _c("strong", [
                                      _vm._v(
                                        "Non trovi la nostra email? Controlla anche la cartella spam."
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
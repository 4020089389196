var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkoutTicketCompliments" }, [
    !_vm.loadingEtc
      ? _c("div", [
          _c("div", { class: _vm.canUseEtc ? "" : "cannotUseEtc" }, [
            _c("div", { staticClass: "edenredGui" }, [
              _c("div", { staticClass: "edenredPanel" }, [
                _vm._m(0),
                _c("div", { staticClass: "edenredText" }, [
                  _c("span", [_vm._v("Accesso a Edenred effettuato")]),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass: "etcLogout",
                      on: {
                        click: function ($event) {
                          return _vm.mixinLogout()
                        },
                      },
                    },
                    [_vm._v("esci")]
                  ),
                ]),
              ]),
            ]),
            !_vm.canUseEtc
              ? _c("div", { staticClass: "cannotUseEtcAlert" }, [
                  _c("span", { staticClass: "danger" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "16",
                          viewBox: "0 0 18 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M16.878 11.3112C15.3892 8.73332 12.9524 4.5122 11.4636 1.93472C9.97497 -0.644906 7.5382 -0.644906 6.04921 1.93472C4.56129 4.5122 2.12435 8.73332 0.635359 11.3112C-0.853271 13.8904 0.364487 16 3.34264 16C6.32044 16 11.1929 16 14.1703 16C17.1481 16 18.3668 13.8904 16.878 11.3112ZM8.68462 13.1522C7.9226 13.1522 7.30577 12.5348 7.30577 11.773C7.30577 11.011 7.9226 10.3934 8.68462 10.3934C9.44681 10.3934 10.064 11.011 10.064 11.773C10.064 12.5346 9.44681 13.1522 8.68462 13.1522ZM10.1151 8.12954C10.1151 8.91926 9.47435 9.56024 8.68462 9.56024C7.89507 9.56024 7.25445 8.91926 7.25445 8.12954V5.67775C7.25445 4.88784 7.89507 4.24723 8.68462 4.24723C9.47435 4.24723 10.1151 4.88784 10.1151 5.67775V8.12954Z",
                            fill: "#FF0000",
                          },
                        }),
                      ]
                    ),
                    _vm._v(
                      "\n                    Non sono disponibili Ticket Compliments sul tuo account Edenred\n                "
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "vouchersTable" }, [
                  _vm._m(1),
                  _c("hr"),
                  _c(
                    "div",
                    { staticClass: "vouchersBlock" },
                    [
                      _vm._l(_vm.vouchers, function (voucher, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "vouchersTableRow vouchers" },
                            [
                              _c("div", { staticClass: "voucherValue" }, [
                                _vm._v("€ " + _vm._s(voucher.value)),
                              ]),
                              _c("div", { staticClass: "voucherCount" }, [
                                voucher.used > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "setVoucherIcon minusOk",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeVoucher(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "15",
                                              height: "15",
                                              viewBox: "0 0 15 15",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "7.5",
                                                cy: "7.5",
                                                r: "7.5",
                                                fill: "#3471DC",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M5.85141 8.04H9.15141V9.288H5.85141V8.04Z",
                                                fill: "white",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "setVoucherIcon minusKo" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "15",
                                              height: "15",
                                              viewBox: "0 0 15 15",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "7.5",
                                                cy: "7.5",
                                                r: "7.5",
                                                fill: "#C4C4C4",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M5.85141 8.04H9.15141V9.288H5.85141V8.04Z",
                                                fill: "white",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                _c(
                                  "span",
                                  _vm._b({}, "span", _vm.calculating, false),
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(voucher.used) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                voucher.used < voucher.count
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "setVoucherIcon plusOk",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addVoucher(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "15",
                                              height: "15",
                                              viewBox: "0 0 15 15",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "7.5",
                                                cy: "7.5",
                                                r: "7.5",
                                                fill: "#3471DC",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z",
                                                fill: "white",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "setVoucherIcon plusKo" },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "15",
                                              height: "15",
                                              viewBox: "0 0 15 15",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "7.5",
                                                cy: "7.5",
                                                r: "7.5",
                                                fill: "#C4C4C4",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M10.2729 8.4H8.14894V10.5H6.86494V8.4H4.74094V7.2H6.86494V5.1H8.14894V7.2H10.2729V8.4Z",
                                                fill: "white",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                              ]),
                              _c("div", { staticClass: "voucherTotal" }, [
                                _vm._v("€ " + _vm._s(voucher.amount)),
                              ]),
                            ]
                          ),
                          _c("hr"),
                        ])
                      }),
                      _c("div", { staticClass: "vouchersTableRow vouchers" }, [
                        _c("div", { staticClass: "voucherValue" }, [
                          _vm._v("Totale"),
                        ]),
                        _c("div", { staticClass: "voucherCount" }),
                        _c("div", { staticClass: "voucherTotal" }, [
                          _vm._v("€ " + _vm._s(_vm.etcTotal)),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
            _c("div", { staticClass: "etcLower" }, [
              _vm.canUseEtc
                ? _c(
                    "div",
                    { staticClass: "etcAutoSelect" },
                    [
                      _c("p", [
                        _vm._v(
                          "Utilizza la combinazione di buoni Ticket Compliments consigliata"
                        ),
                      ]),
                      _c(
                        "CustomSwitch",
                        _vm._b(
                          {
                            attrs: {
                              status: _vm.etcAutoSelect,
                              size: "sm",
                              switchUniqueKey: "etc-auto-select",
                            },
                            on: {
                              "switch-change": _vm.etcAutoSelectCalculation,
                            },
                          },
                          "CustomSwitch",
                          _vm.calculating,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ])
      : _c("div", [_c("Spinner")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edenredLogo" }, [
      _c("img", {
        attrs: { src: "/assets/images/etc_transparent_checkout.png" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vouchersBlock" }, [
      _c("div", { staticClass: "vouchersTableRow headingRow" }, [
        _c("div", [_vm._v("buono")]),
        _c("div", [_vm._v("quantità")]),
        _c("div", [_vm._v("totale")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="checkoutResume">
        <h5 class="sidebarStepTitle">Il tuo carrello</h5>
        <div class="sidebarResumeBlock salabam">
            <br><br>
            <div class="sidebarRow">
                <span>
                    Salabam {{focus | capitalize}} {{band | capitalize}} <span>{{nights}} <span v-if="nights > 1">notti</span><span v-else>notte</span></span>
                </span>
                <span>{{mixinRoundTwoDigits(price)}} €</span>
            </div>

            <div><hr /></div>

            <div class="sidebarRow">
                <span>Totale</span>
                <span>{{mixinRoundTwoDigits(price)}} €</span>
            </div>

            <!-- sconto disponibile riga 1 -->
            <div class="sidebarRow" v-if="mixinHasDiscount()>0">
                <span class="lighter">Sconto speciale {{$config.integration.customerDisplayName}} -{{100-100*mixinHasDiscount()}}%</span>
                <span class="nowrap">{{mixinRoundTwoDigits(price * (1-mixinHasDiscount()))}} €</span>
            </div>

            <!-- sconto disponibile riga 2 -->
            <div class="sidebarRow" v-if="mixinHasDiscount() > 0">
                <span class="lighter" v-if="isJointly && !hasEnoughAvailability">Da pagare con carta di credito sul portale Jointly</span>
                <span class="lighter" v-else>Totale per te</span>
                <span class="nowrap">{{mixinRoundTwoDigits(price*mixinHasDiscount())}} €</span>
            </div>
            
            <!-- residuo credito welfare: lo mostro solo se NON ci sono sconti, per non riempire troppo l'elemento -->
            <div class="sidebarRow" v-if="mixinHasDiscount() == -1 && !isJointly && (availability > 0)">
                <span class="lighter">Credito residuo dopo il pagamento</span>
                <span v-if="hasEnoughAvailability">{{mixinRoundTwoDigits(availability-price)}} €</span>
                <span v-else>0 €</span>
            </div>
        </div>

        <div class="sidebarResumeBlock fees" v-if="this.$route.name == 'checkout' && fees !== 0">
            <strong>Costi obbligatori non inclusi</strong>
            <div class="sidebarRow" v-if="bookingData.totals.mandatory_tax !== 0">
                <span v-b-popover.hover.top="'Il costo della tassa di soggiorno viene definito dall\'Amministrazione Comunale, deve essere pagato in struttura ed è soggetto a variazioni. Il costo che vedi sul nostro sito è stato inserito dalla struttura alberghiera e non possiamo garantire che sia stato inserito correttamente e che sia aggiornato. Se desideri informazioni definitive ti invitiamo a consultare il sito dell\'Amministrazione Comunale o a contattare la struttura alberghiera.'">
                  Tassa di soggiorno
                  <SvgInfoCircle />
                </span>
                <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_tax)}} €</span>
            </div>
            <div class="sidebarRow" v-if="bookingData.totals.mandatory_fee !== 0">
                <span>Spese di pulizia ed utenze</span>
                <span>{{mixinRoundTwoDigits(bookingData.totals.mandatory_fee)}} €</span>
            </div>
            <div class="sidebarRow" v-if="bookingData.totals.resort_fee !== 0">
                <span>Resort fee</span>
                <span>{{mixinRoundTwoDigits(bookingData.totals.resort_fee)}} €</span>
            </div>
            <div class="d-flex justify-content-center">
                <small>Da pagare in struttura al check-in</small>
            </div>
        </div>
        <div class="sidebarResumeBlock" v-else-if="this.$route.name == 'checkout'">

          <strong>Costi obbligatori non inclusi</strong>
          &nbsp;
          <div class="d-flex justify-content-center">
            La struttura non ha comunicato altri costi obbligatori da pagare in loco. Controlla anche il capitolo Informazioni importanti che trovi in questa pagina oppure contattaci per maggiori informazioni.
          </div>

        </div>
    </div>
</template>

<script>
import SvgInfoCircle from './../../svg/info-circle'
export default {
    name: 'checkoutResume',
    props:
    {
        bookingData: Object,
        price: Number,
        fees: Number,
    },
    components:
    {
        SvgInfoCircle
    },
    data()
    {
        let boxset = this.mixinGetBoxsetFromConfig(this.bookingData.boxset_id)
        return {
            boxset: boxset.boxset,
            focus: this.$store.state.focus,
            band: this.$store.state.activeBand,
            nights: this.$store.state.dates.nights,
            availability: this.$config.integration.availability,
            isJointly: this.mixinGetIntegrationInfo().integration.integration == 'jointly',
            hasEnoughAvailability: (this.$config.integration.availability >= this.price),
        }
    },
    mounted()
    {
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .checkoutResume
    {
        .lighter
        {
            //opacity:0.75;
        }
    }
    /*
    .checkoutResume
    {
        .sidebarRow:last-child
        {
            margin-bottom:0 !important;
        }
        .sidebarResumeBlock
        {
            font-size: 14px;
            font-weight:400;
            &:not(:last-child)
            {
                border-bottom:1px solid rgba($black,0.25);
                margin-bottom:0.75rem;
                padding-bottom:0.75rem;
                small
                {
                    margin-bottom:8px;
                }
            }

            strong
            {
                font-size: 16px;
            }
            small
            {
                display:block;
                margin-top:8px;
            }
        }
    }
    */
</style>
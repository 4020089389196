<template>
    <div class="checkoutUserInfo">
        <User />
    </div>
</template>

<script>
import User from './../topbar/user'
export default {
    name: 'checkoutUserInfo',
    components:
    {
        User
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    .checkoutUserInfo
    {
        .logo,
        .logout
        {
            display:none;
        }
        .info
        {
            flex:1;
            justify-content:flex-start;
        }
        .content
        {
            flex:1;
            padding-left:1.5rem;
        }
        .displayName,
        .availabilty
        {
            font-size: 18px;
            font-weight: normal;
        }
        .availabilty
        {
            font-size: 17px;
        }
    }
</style>
var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebarSso", attrs: { id: "sidebar" } }, [
    _c(
      "div",
      {
        staticClass: "checkoutBack",
        on: {
          click: function ($event) {
            return _vm.mixinGoBack()
          },
        },
      },
      [_c("BtnBack", { attrs: { text: "Torna indietro" } })],
      1
    ),
    !_vm.useCreditCard &&
    !_vm.enoughAvailability &&
    _vm.mixinGetIntegrationInfo().integration.integration !== "jointly"
      ? _c(
          "div",
          [
            _c(
              "b-alert",
              {
                staticClass: "text-center",
                attrs: { variant: "danger", show: "" },
              },
              [
                _c("b", [
                  _c("span", [_vm._v("Credito welfare")]),
                  _vm._v(" non sufficiente!"),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "sidebar-inner" }, [
      _c(
        "div",
        { staticClass: "hasBackground", attrs: { id: "sidebarCheckout" } },
        [
          _c(
            "div",
            { staticClass: "sidebarStep bgDark" },
            [
              _c("CheckoutUserInfo"),
              _c("hr"),
              _c("CheckoutResume", {
                attrs: {
                  bookingData: _vm.bookingData,
                  price: _vm.bookingData.public_price,
                  fees: _vm.fees,
                },
              }),
            ],
            1
          ),
          _vm.enoughAvailability ||
          _vm.useCreditCard ||
          _vm.mixinGetIntegrationInfo().integration.integration == "jointly"
            ? _c(
                "div",
                { staticClass: "sidebarStep bgGray" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "checkout-form",
                        "valid-feedback": _vm.validFeedback(),
                      },
                    },
                    [
                      _c("h5", { staticClass: "sidebarAccordionTitle" }, [
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "checkoutDynamicBlockTitle" },
                            [
                              _c("span", { staticClass: "stepNumber" }, [
                                _vm._v("1"),
                              ]),
                              _vm._v(" Dati personali "),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "sidebarResumeBlock" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.allowCustomBeneficiary,
                                  expression: "allowCustomBeneficiary",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c(
                                  "div",
                                  { staticClass: "customInputBlock" },
                                  [
                                    _c("b-form-input", {
                                      ref: "customBeneficiaryFirstname",
                                      staticClass: "form-control-lg",
                                      attrs: {
                                        required: true,
                                        type: "text",
                                        placeholder: "Mario",
                                      },
                                      model: {
                                        value: _vm.customBeneficiaryFirstname,
                                        callback: function ($$v) {
                                          _vm.customBeneficiaryFirstname = $$v
                                        },
                                        expression:
                                          "customBeneficiaryFirstname",
                                      },
                                    }),
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value: "Il tuo nome",
                                          expression: "'Il tuo nome'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "svgInfo",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c(
                                  "div",
                                  { staticClass: "customInputBlock" },
                                  [
                                    _c("b-form-input", {
                                      ref: "customBeneficiaryLastname",
                                      staticClass: "form-control-lg",
                                      attrs: {
                                        required: true,
                                        type: "text",
                                        placeholder: "Rossi",
                                      },
                                      model: {
                                        value: _vm.customBeneficiaryLastname,
                                        callback: function ($$v) {
                                          _vm.customBeneficiaryLastname = $$v
                                        },
                                        expression: "customBeneficiaryLastname",
                                      },
                                    }),
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value: "Il tuo cognome",
                                          expression: "'Il tuo cognome'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "svgInfo",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c("h5", { staticClass: "sidebarStepTitle" }, [
                                  _vm._v("Scegli il grado di parentela"),
                                ]),
                              ]),
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c(
                                  "div",
                                  { staticClass: "customInputBlock" },
                                  [
                                    _c("b-form-select", {
                                      ref: "checkoutGuestRelation",
                                      staticClass: "form-control-lg",
                                      attrs: {
                                        required: true,
                                        options: _vm.beneficiaryRelations,
                                      },
                                      model: {
                                        value: _vm.guestRelation,
                                        callback: function ($$v) {
                                          _vm.guestRelation = $$v
                                        },
                                        expression: "guestRelation",
                                      },
                                    }),
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "L'intestatario del voucher deve essere uno dei possibili beneficiari del tuo piano welfare, quali i familiari",
                                          expression:
                                            "'L\\'intestatario del voucher deve essere uno dei possibili beneficiari del tuo piano welfare, quali i familiari'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "svgInfo",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c(
                                  "div",
                                  { staticClass: "customInputBlock" },
                                  [
                                    _c("b-form-input", {
                                      ref: "customBeneficiaryFiscalCode",
                                      staticClass: "form-control-lg",
                                      attrs: {
                                        required: true,
                                        type: "text",
                                        placeholder: "codice fiscale",
                                      },
                                      model: {
                                        value: _vm.customBeneficiaryFiscalCode,
                                        callback: function ($$v) {
                                          _vm.customBeneficiaryFiscalCode = $$v
                                        },
                                        expression:
                                          "customBeneficiaryFiscalCode",
                                      },
                                    }),
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value: "Codice fiscale",
                                          expression: "'Codice fiscale'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "svgInfo",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.allowCustomBeneficiary,
                                  expression: "!allowCustomBeneficiary",
                                },
                              ],
                            },
                            [
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c("h5", { staticClass: "sidebarStepTitle" }, [
                                  _vm._v("Scegli il beneficiario"),
                                ]),
                              ]),
                              _c("div", { staticClass: "sidebarRow" }, [
                                _c(
                                  "div",
                                  { staticClass: "customInputBlock" },
                                  [
                                    _c("b-form-select", {
                                      ref: "checkoutBeneficiaries",
                                      staticClass: "form-control-lg",
                                      attrs: {
                                        required: true,
                                        options: _vm.beneficiaries,
                                      },
                                      model: {
                                        value: _vm.beneficiaryId,
                                        callback: function ($$v) {
                                          _vm.beneficiaryId = $$v
                                        },
                                        expression: "beneficiaryId",
                                      },
                                    }),
                                    _c("SvgInfoCircle", {
                                      directives: [
                                        {
                                          name: "b-popover",
                                          rawName: "v-b-popover.hover.top",
                                          value:
                                            "Quella che vedi quì è la lista dei possibili beneficiari del tuo credito welfare così come inserita nel tuo portale welfare. Se il nome del viaggiatore non risulta tra i beneficiari devi tornare sul portale welfare, inserirlo tra i beneficiari e poi fare di nuovo dall'inizio il processo di prenotazione su VadoBay",
                                          expression:
                                            "'Quella che vedi quì è la lista dei possibili beneficiari del tuo credito welfare così come inserita nel tuo portale welfare. Se il nome del viaggiatore non risulta tra i beneficiari devi tornare sul portale welfare, inserirlo tra i beneficiari e poi fare di nuovo dall\\'inizio il processo di prenotazione su VadoBay'",
                                          modifiers: { hover: true, top: true },
                                        },
                                      ],
                                      staticClass: "svgInfo",
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "sidebarRow" }, [
                            _c(
                              "div",
                              { staticClass: "customInputBlock" },
                              [
                                _c("b-form-input", {
                                  ref: "checkoutEmail",
                                  staticClass: "form-control-lg",
                                  attrs: {
                                    required: true,
                                    type: "email",
                                    placeholder: "Email",
                                  },
                                  model: {
                                    value: _vm.guestEmail,
                                    callback: function ($$v) {
                                      _vm.guestEmail = $$v
                                    },
                                    expression: "guestEmail",
                                  },
                                }),
                                _c("SvgInfoCircle", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "Il voucher Salabam con il codice e le istruzioni su come prenotare sarà inviato a questo indirizzo",
                                      expression:
                                        "'Il voucher Salabam con il codice e le istruzioni su come prenotare sarà inviato a questo indirizzo'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "svgInfo",
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "sidebarRow" }, [
                            _c(
                              "div",
                              { staticClass: "customInputBlock" },
                              [
                                _c("b-form-input", {
                                  ref: "checkoutEmailConfirm",
                                  staticClass: "form-control-lg",
                                  attrs: {
                                    required: true,
                                    type: "email",
                                    placeholder: "Conferma email",
                                  },
                                  model: {
                                    value: _vm.guestEmailConf,
                                    callback: function ($$v) {
                                      _vm.guestEmailConf = $$v
                                    },
                                    expression: "guestEmailConf",
                                  },
                                }),
                                _c("SvgInfoCircle", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "Te la facciamo riscrivere per scoprire eventuali errori di digitazione",
                                      expression:
                                        "'Te la facciamo riscrivere per scoprire eventuali errori di digitazione'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "svgInfo",
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "sidebarRow" }, [
                            _c(
                              "div",
                              { staticClass: "customInputBlock" },
                              [
                                _c("b-form-input", {
                                  ref: "checkoutPhone",
                                  staticClass: "form-control-lg",
                                  attrs: {
                                    required: true,
                                    type: "tel",
                                    placeholder: "Telefono",
                                    autocomplete: "tel",
                                  },
                                  model: {
                                    value: _vm.guestPhone,
                                    callback: function ($$v) {
                                      _vm.guestPhone = $$v
                                    },
                                    expression: "guestPhone",
                                  },
                                }),
                                _c("SvgInfoCircle", {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover.top",
                                      value:
                                        "Avere il tuo numero ci permetterà di contattarti velocemente in caso di qualsiasi problema",
                                      expression:
                                        "'Avere il tuo numero ci permetterà di contattarti velocemente in caso di qualsiasi problema'",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass: "svgInfo",
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("hr", { staticClass: "stepSeparator" }),
                          _vm.sidebarBlocks.checkoutBillToPax.initialize
                            ? _c("CheckoutBillToPax", {
                                ref: _vm.sidebarBlocks.checkoutBillToPax.ref,
                                attrs: {
                                  inputSize:
                                    _vm.sidebarBlocks.checkoutBillToPax
                                      .inputSize,
                                },
                                on: {
                                  sidebarBlockChanged: _vm.sidebarBlockChange,
                                },
                              })
                            : _vm._e(),
                          _vm.sidebarBlocks.checkoutBillToPax.initialize
                            ? _c("hr", { staticClass: "stepSeparator" })
                            : _vm._e(),
                          _vm.sidebarBlocks.checkoutTicketCompliments.initialize
                            ? _c("CheckoutTicketCompliments", {
                                ref: _vm.sidebarBlocks.checkoutTicketCompliments
                                  .ref,
                                attrs: { checkoutAmount: _vm.checkoutAmount },
                                on: {
                                  sidebarBlockChanged: _vm.sidebarBlockChange,
                                },
                              })
                            : _vm._e(),
                          _vm.sidebarBlocks.checkoutTicketCompliments.initialize
                            ? _c("hr", { staticClass: "stepSeparator" })
                            : _vm._e(),
                          _c("div", [
                            _vm.useCreditCard &&
                            _vm.mixinRoundTwoDigits(_vm.creditCardAmount) > 0
                              ? _c(
                                  "div",
                                  [
                                    _c("Stripe", {
                                      ref: "stripe",
                                      attrs: { amount: _vm.creditCardAmount },
                                      on: {
                                        "stripe-change": _vm.stripeChange,
                                        "stripe-confirm": _vm.stripeConfirm,
                                      },
                                    }),
                                    _c("hr", { staticClass: "stepSeparator" }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "privacy-box" },
                            [
                              _c("b-form-checkbox", {
                                ref: "checkoutPrivacy",
                                attrs: {
                                  required: "",
                                  type: "checkbox",
                                  name: "checkbox",
                                  id: "checkbox-privacy",
                                },
                                model: {
                                  value: _vm.guestPrivacy,
                                  callback: function ($$v) {
                                    _vm.guestPrivacy = $$v
                                  },
                                  expression: "guestPrivacy",
                                },
                              }),
                              _c(
                                "label",
                                { attrs: { for: "checkbox-privacy" } },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nounderline",
                                      attrs: {
                                        to: { name: "terms" },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "secondary" }, [
                                        _vm._v(
                                          "Ho preso visione delle condizioni d'uso e le accetto"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "privacy-box" },
                            [
                              _c("b-form-checkbox", {
                                ref: "checkoutGdpr",
                                attrs: {
                                  required: "",
                                  type: "checkbox",
                                  name: "checkbox",
                                  id: "checkbox-gdpr",
                                },
                                model: {
                                  value: _vm.guestGdpr,
                                  callback: function ($$v) {
                                    _vm.guestGdpr = $$v
                                  },
                                  expression: "guestGdpr",
                                },
                              }),
                              _c("label", { attrs: { for: "checkbox-gdpr" } }, [
                                _c("p", { staticClass: "mb-0" }, [
                                  _c("span", { staticClass: "secondary" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.site.urlPrivacy,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "Ho preso visione della privacy policy e la accetto"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _vm.disclaimerCheckout !== undefined
                            ? _c("div", { staticClass: "privacy-box" }, [
                                _c(
                                  "label",
                                  { attrs: { for: "checkbox-gdpr" } },
                                  [
                                    _c("p", {
                                      staticClass: "checkboxInfo",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.disclaimerCheckout
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("br"),
                          _vm.loading
                            ? _c("Spinner", { attrs: { msg: _vm.loadingText } })
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _vm.mixinGetIntegrationInfo().mode ==
                                  "booking"
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.validFeedback() !== "ok",
                                          },
                                          on: { click: _vm.checkoutConfirm },
                                        },
                                        [_vm._v("PRENOTA!")]
                                      )
                                    : _vm._e(),
                                  _vm.mixinGetIntegrationInfo().mode == "boxset"
                                    ? _c("div", [
                                        _vm.enoughAvailability ||
                                        _vm.mixinGetIntegrationInfo()
                                          .integration.integration == "jointly"
                                          ? _c(
                                              "div",
                                              [
                                                _vm.otp_required &&
                                                _vm.otp_check == false
                                                  ? _c("Otp", {
                                                      attrs: {
                                                        button_variant:
                                                          "secondary",
                                                        disabled:
                                                          _vm.validFeedback() !==
                                                          "ok",
                                                        cta: "ACQUISTA!",
                                                      },
                                                      on: {
                                                        "otp-check": function (
                                                          value
                                                        ) {
                                                          _vm.otp_check = value
                                                        },
                                                        "otp-event": function (
                                                          value
                                                        ) {
                                                          this$1.trackOtpEvent(
                                                            value
                                                          )
                                                        },
                                                        "otp-purchase":
                                                          function ($event) {
                                                            return _vm.checkoutConfirm()
                                                          },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.otp_required == false ||
                                                (_vm.otp_required &&
                                                  _vm.otp_check)
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.validFeedback() !==
                                                            "ok",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.checkoutConfirm,
                                                        },
                                                      },
                                                      [_vm._v("PRENOTA!")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm.mixinGetIntegrationInfo()
                                              .welfare == false
                                          ? _c(
                                              "div",
                                              [
                                                _vm.otp_required &&
                                                _vm.otp_check == false
                                                  ? _c("Otp", {
                                                      attrs: {
                                                        button_variant:
                                                          "secondary",
                                                        disabled:
                                                          _vm.validFeedback() !==
                                                            "ok" ||
                                                          !_vm.stripeInfoCheck,
                                                        cta: "ACQUISTA!",
                                                      },
                                                      on: {
                                                        "otp-check": function (
                                                          value
                                                        ) {
                                                          _vm.otp_check = value
                                                        },
                                                        "otp-event": function (
                                                          value
                                                        ) {
                                                          this$1.trackOtpEvent(
                                                            value
                                                          )
                                                        },
                                                        "otp-purchase":
                                                          function ($event) {
                                                            return _vm.getIntentClientSecret()
                                                          },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm.otp_required == false ||
                                                (_vm.otp_required &&
                                                  _vm.otp_check)
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.validFeedback() !==
                                                              "ok" ||
                                                            !_vm.stripeInfoCheck,
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.getIntentClientSecret,
                                                        },
                                                      },
                                                      [_vm._v("ACQUISTA!")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c("span", [_vm._v("ACQUISTA!")]),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  ),
                  this.$route.name == "confirm"
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-block btn-outline-secondary",
                            attrs: { href: "/" },
                          },
                          [_vm._v("Torna alla Home")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }